import React, { useState } from "react";
import ResetPassowrdComponent from "../../components/forgotpass/reset-password";
import AuthApi from "../../api/auth";
import { useQuery } from "../../utils/index";
import { useSelector } from "react-redux";

const ResetPassword = () => {
  const query = useQuery();

  const [resetPasswordStatus, setResetPasswordStatus] = useState({ status: "initial", message: "" });
  const [resetPasswordError, setPasswordError] = useState("");

  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  const userStatus = useSelector((state) => state.user.status);

  const onSubmit = async (password, passwordConfirmation) => {
    setResetPasswordStatus({ status: "loading" });
    const token = query.get("token");
    const email = query.get("email");

    if (password === passwordConfirmation) {
      const res = await AuthApi.resetPassword({
        token,
        email: email.replace(/ /g, "+"), // TODO: backend should send this encoded properly
        password,
        passwordConfirmation,
      });
      if (res.status === 200) {
        setResetPasswordStatus({ status: "success", message: res.response?.data?.message || "" });
      } else {
        setPasswordError(res.response.data?.message);
        setResetPasswordStatus({ status: "error", message: res.response?.data?.message || "" });
      }
    } else {
      setResetPasswordStatus({ status: "error", message: "" });
    }
  };

  return (
    <>
      {!isAuthenticated && userStatus !== "loading" && (
        <ResetPassowrdComponent
          resetPasswordError={resetPasswordError}
          resetPasswordStatus={resetPasswordStatus}
          onSubmit={onSubmit}
        />
      )}
    </>
  );
};

export default ResetPassword;
