import { Box, Button, Container, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState, useEffect } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import Link from "../link";
// theme
import theme from "../../theme";
// local components
import TextField from "../text-field";

const propTypes = {};
const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
    display: "flex",
    justifyContent: "center",
  },
  box: {
    width: "400px",
    marginTop: theme.spacing(3),
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  form: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(3),
  },
  links: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(1),
  },
  link: {},
}));
const ResetPasswordComponent = ({ onSubmit, resetPasswordStatus }) => {
  const classes = useStyles(theme);

  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [isPasswordFormatCorrect, setIsPasswordFormatCorret] = useState();
  const [isPasswordConfirmationFormatCorrect, setIsPasswordConfirmationFormatCorrect] = useState();

  const { i18n } = useLingui();

  const handleSubmit = () => onSubmit(password, passwordConfirmation);

  useEffect(() => {
    ValidatorForm.removeValidationRule("isPasswordMatch");
    ValidatorForm.addValidationRule("isPasswordMatch", (value) => {
      if (value != password) {
        return false;
      }
      return true;
    });

    return () => {
      ValidatorForm.removeValidationRule("isPasswordMatch");
    };
  }, [password, passwordConfirmation]);

  const renderError = (typeError) => {
    switch (typeError) {
      case "passwords.token":
        return (
          <Trans>
            Lo sentimos, el token de reinicio de contraseña ya no es válido. Intenta recuperar la contraseña desde el
            e-mail nuevamente
          </Trans>
        );
      default:
        return <Trans>Ocurrió un problema, inténtelo de nuevo.</Trans>;
    }
  };

  return (
    <>
      <Container className={classes.container}>
        <Box className={classes.box}>
          <Typography variant="h3">
            <Trans>Puedes setear tu nueva contraseña</Trans>
          </Typography>
          {resetPasswordStatus.status !== "success" && (
            <ValidatorForm onSubmit={handleSubmit} className={classes.form}>
              <Box mt={2} display="flex" flexDirection="column">
                <TextField
                  label=""
                  placeholder={`${i18n._(t`Contraseña`)}`}
                  value={password}
                  validatorListener={(v) => {
                    setIsPasswordFormatCorret(v);
                  }}
                  onChange={({ target }) => {
                    setPassword(target.value);
                  }}
                  type="password"
                  validators={["required", "matchRegexp:^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,24}$"]}
                  errorMessages={[
                    `${i18n._(t`Este campo es obligatorio`)}`,
                    `${i18n._(
                      t`La contraseña debe tener entre 8 y 24 caracteres y contener al menos un número, una letra minúscula y una mayúscula.`,
                    )}`,
                  ]}
                  fullWidth
                />
                <TextField
                  label=""
                  placeholder={`${i18n._(t`Confirmar contraseña`)}`}
                  value={passwordConfirmation}
                  validatorListener={(v) => {
                    setIsPasswordConfirmationFormatCorrect(v);
                  }}
                  onChange={({ target }) => {
                    setPasswordConfirmation(target.value);
                  }}
                  type="password"
                  validators={["required", "isPasswordMatch"]}
                  errorMessages={[
                    `${i18n._(t`Este campo es obligatorio`)}`,
                    `${i18n._(t`Debes confirmar la misma contraseña.`)}`,
                  ]}
                  fullWidth
                />
              </Box>

              {resetPasswordStatus.status.match(/error/) && (
                <>
                  <Box mt={2}>
                    <Typography color="error" variant="body1">
                      {renderError(resetPasswordStatus.message)}
                    </Typography>
                  </Box>
                  <Box mt={2}>
                    <Button variant="contained" color="primary">
                      <Link style={{ color: theme.palette.white }} to="/forgot/">
                        <Trans>Ir a recuperar contraseña</Trans>
                      </Link>
                    </Button>
                  </Box>
                </>
              )}
              <Box mt={2}>
                <Button
                  disabled={
                    resetPasswordStatus.status === "loading" ||
                    !isPasswordFormatCorrect ||
                    !isPasswordConfirmationFormatCorrect ||
                    password.length <= 0 ||
                    passwordConfirmation.length <= 0 ||
                    resetPasswordStatus.status === "error"
                  }
                  size="large"
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  fullWidth
                >
                  <Trans>Reiniciar contraseña</Trans>
                  {resetPasswordStatus.status === "loading" && "..."}
                </Button>
              </Box>
            </ValidatorForm>
          )}
          {resetPasswordStatus.status === "success" && (
            <>
              <Box mt={2}>
                <Typography variant="h6">
                  <Trans>La contraseña se cambió con éxito</Trans>
                </Typography>
              </Box>
              <Box mt={2}>
                <Button variant="contained" color="primary">
                  <Link style={{ color: theme.palette.white }} to="/signin/">
                    <Trans>Ir al login</Trans>
                  </Link>
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Container>
    </>
  );
};

export default ResetPasswordComponent;
